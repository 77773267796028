import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload'
import '@/assets/css/global.css'
import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'
import { initJsAndAppBridge } from './assets/js/blhjsapi_es6'
Vue.config.productionTip = false

Vue.use(VueLazyload,
  {
    preLoad: 1.3,
    attempt: 2
  }
)

if(process.env.NODE_ENV === 'production') {
  Vue.use(VueGtag, {
    config: { id: 'G-WVX9SV7ZTM' }
  }, router)
}

initJsAndAppBridge()

Vue.use(Meta)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
