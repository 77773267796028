/*
最近修改于20200628
*/
/* 一、参考链接：
 *1. 微信JS-SDK说明文档：https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html
 *2. 支付宝JS-SDK说明文档：https://myjsapi.alipay.com/alipayjsapi/index.html
 *3. WebViewJavaScriptBridge基本使用：https://www.jianshu.com/p/d12ec047ce52
 *4. WebViewJavaScriptBridge深入剖析：https://www.jianshu.com/p/732dec7b1700
 */

/* eslint-disable */
/* 工具方法 */
var bUtils = {
	// 是否IOS(包含ipad平板)
	isIOS: function(){
	  return /iPhone|iPod|ios|iPad/i.test(window.navigator.userAgent)
	},
	// 是否Android
	isAndroid: function(){
	  return /Android/i.test(window.navigator.userAgent)
	},
	// 生成长度为28位的全局唯一ID
	generateUUID: function(){
		var randomPart1Arr = [];
		var hexDigits = '0123456789abcdefghijklmnopqrstuvwxyz';
		for(var i = 0; i < 20; i++){
			randomPart1Arr[i] = hexDigits.substr(Math.floor(Math.random() * hexDigits.length), 1);
		}

		return randomPart1Arr.join('') + (new Date().getTime() + '').slice(-8);
	},
	// 配置与ios端交互的通信机制
	setupWebViewJavascriptBridge: function(callback){
		if(window.WebViewJavascriptBridge){
			return callback(WebViewJavascriptBridge);
		}
		if(window.WVJBCallbacks){
			return window.WVJBCallbacks.push(callback);
		}
		window.WVJBCallbacks = [callback];
		var WVJBIframe = document.createElement('iframe');
		WVJBIframe.style.display = 'none';
		WVJBIframe.src = 'https://__bridge_loaded__';
		document.documentElement.appendChild(WVJBIframe);
		setTimeout(function(){
			document.documentElement.removeChild(WVJBIframe)
		}, 0)
	}
}

/* 全局变量 */
// js调用native交互的回调函数相关
var EMPTY_CALLBACK_ID = 'cb_empty_';
var responseCallbacks = {};
var hasValidAndroidCall = false; // Android端是否向window注入了有效的调用参数，默认没有

/* 初始化与原生交互的通信机制 */
function initJsAndAppBridge(){
	if(bUtils.isIOS()){
		// ios环境
		bUtils.setupWebViewJavascriptBridge(function(bridge){});
	}else if(bUtils.isAndroid()){
		// android环境
		// 接收返回的参数和回调ID
    window.androidCallJS = function(responseObj, callbackId){
      console.log(responseObj, callbackId)
    	// 当有有效的回调ID，且回调集合里有对应的回调('cb_empty3_213213131')
    	var reg = new RegExp('^'+EMPTY_CALLBACK_ID, 'i')
    	if((callbackId && !reg.test(callbackId)) && (typeof responseCallbacks[callbackId] === 'function')){
    		var tempResponseObj = null;
    		try{
    			tempResponseObj = JSON.parse(responseObj);
    		}catch(e){
    			tempResponseObj = null;
    		}

    		responseCallbacks[callbackId](tempResponseObj);

    		delete responseCallbacks[callbackId];
    	}
    }
	}
}

/*** js调用native带回调的交互接口实现 ***/
// 基础交互接口
function baseCallHandler(handlerName, optionObj, callbackFn){
	optionObj = JSON.stringify(optionObj)

	if(bUtils.isIOS()){
		// ios环境
		bUtils.setupWebViewJavascriptBridge(bridge => {
			if(typeof callbackFn === 'function'){
				// 有回调函数
				bridge.callHandler(handlerName, optionObj, function(responseObj){
					var tempResponseObj = null;
	    		try{
	    			tempResponseObj = JSON.parse(responseObj);
	    		}catch(e){
	    			tempResponseObj = null;
	    		}

					callbackFn(tempResponseObj);
				})
			}else{
				// 无回调函数
				bridge.callHandler(handlerName, optionObj)
			}
    })
	}else if(bUtils.isAndroid()){
		// android环境

		// 检查window.nativeAndroid.callNative是否存在，并且为函数
		if(!hasValidAndroidCall){
			if(window.nativeAndroid && Object.prototype.toString.call(window.nativeAndroid) === '[object Object]' && typeof window.nativeAndroid.callNative === 'function'){
				hasValidAndroidCall = true;
			}
		}

		if(hasValidAndroidCall){
			// 当有回调函数时，生成唯一的回调函数ID，默认没有回调函数
			var callbackId = '';
			if(typeof callbackFn === 'function'){
				callbackId = 'cb_needRes_'+bUtils.generateUUID();
				responseCallbacks[callbackId] = callbackFn;
			}else{
				callbackId = EMPTY_CALLBACK_ID+bUtils.generateUUID();
			}
			window.nativeAndroid.callNative(handlerName, optionObj, callbackId);
		}
	}
}

/*** 基础功能类 ***/
// 1. 获取当前运行环境的信息(包含当前app基本参数、用户信息、支付能力、路由能力、补充信息)
function getRuntimeInfo(optionObj, callbackFn){
	baseCallHandler('getRuntimeInfo', optionObj, callbackFn);
}
// 7. 使用app的TA统计
function tongjiTa(optionObj, callbackFn){
	baseCallHandler('tongjiTa', optionObj, callbackFn);
}

/*** UI操作类 ***/
// 1. 关闭当前的UI
function closeWindow(optionObj, callbackFn){
	baseCallHandler('closeWindow', optionObj, callbackFn);
}
// 2. 隐藏loading
function hideNativeLoading(optionObj, callbackFn){
	baseCallHandler('hideNativeLoading', optionObj, callbackFn);
}

/*** App内部路由类 ***/
// 1. 打开deeplink UI (进入应用内某个页面的接口)
function goDeeplink(optionObj, callbackFn){
	baseCallHandler('goDeeplink', optionObj, callbackFn);
}

export { initJsAndAppBridge,
				 baseCallHandler,
				 getRuntimeInfo, tongjiTa,
				 closeWindow, hideNativeLoading,
				 goDeeplink }
