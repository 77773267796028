import Vue from 'vue'
import VueRouter from 'vue-router'
import * as blhJsapi from '@/assets/js/blhjsapi_es6'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue')
  },
  {
    path: '/player/:id',
    name: 'player',
    component: () => import('@/views/videoPlay.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from,next) => {
  if(navigator.onLine) {
    next()
  } else {
    blhJsapi.closeWindow({
      version: 108,
      data: {}
    })
  }
})
// router.afterEach((to,from) => {
//   window.googletag = window.googletag || {cmd: []};
//   googletag.cmd.push(() => {
//     window.googletag && window.googletag.destroySlots()
//   })
//   if(to.name !== from.name) {
//     setTimeout(() => {
//       window.googletag = window.googletag || {cmd: []};googletag.cmd.push(function() {var slot1 = googletag.defineOutOfPageSlot('/23116449905/kidjoy_interstitial',googletag.enums.OutOfPageFormat.INTERSTITIAL);if (slot1) slot1.addService(googletag.pubads());googletag.enableServices();googletag.display(slot1);
//         googletag.pubads().refresh([slot1]);
//       });

//       window.googletag = window.googletag || {cmd: []};googletag.cmd.push(function() {var slot2 = googletag.defineOutOfPageSlot('/23116449905/kidjoy_anchor1',googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR);if (slot2) slot2.addService(googletag.pubads());googletag.enableServices();googletag.display(slot2);
//         googletag.pubads().refresh([slot2]);
//       });
//     }, 100)
//   }

// })

export default router
