<template>
  <div id="app">
    <router-view/>
    <!-- <div id="AdAAnchor" class="AdAAnchor_Bot">
      <a id='AdAAnchorBtn' @click="AdAAnchorToggle"><i class='AdA_bottomDown'></i></a><div id='AdAAnchorAd'><div id='div-gpt-ad-AdAAnchor'></div></div>
    </div> -->
  </div>
</template>
<script>
export default {
  // methods: {
  //   AdAArrow(){
  //     const e = document.querySelector('#AdAAnchorAd')
  //     const f = document.querySelector('#AdAAnchorBtn')
  //     const g = f.querySelector('i')
  //     if (getComputedStyle(f).getPropertyValue('bottom')=='-16px'){
  //       (e.style.height=='0px') ? (g.className='AdA_topUp') : (g.className='AdA_topDown');
  //     } else {
  //       (e.style.height=='0px') ? (g.className='AdA_bottomUp') : (g.className='AdA_bottomDown')
  //     }
  //   },
  //   AdAAnchorToggle(){
  //     const e = document.querySelector('#AdAAnchorAd') 
  //     e.parentElement.className=='AdAAnchorAd hidden' ? (e.style.height='auto', setTimeout(() => { e.className='AdAAnchorAd'}, 100)) :(e.parentElement.className='AdAAnchorAd hidden', e.style.height='0px')
  //     this.AdAArrow()
  //   }
  // }
}
</script>
<style>
</style>
